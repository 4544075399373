import type { SocketViewsId } from 'types/views/socket';
import { SOCKET_VIEWS_IDS } from 'types/views/socket';

import { getEnvValue, parseEnvJson } from 'configs/app/utils';

const hiddenViews = (() => {
  const parsedValue = parseEnvJson<Array<SocketViewsId>>(getEnvValue('NEXT_PUBLIC_SOCKET_HIDDEN_VIEWS')) || [];

  if (!Array.isArray(parsedValue)) {
    return undefined;
  }

  const result = SOCKET_VIEWS_IDS.reduce((result, item) => {
    result[item] = parsedValue.includes(item);
    return result;
  }, {} as Record<SocketViewsId, boolean>);

  return result;
})();

const config = Object.freeze({
  hiddenViews,
});

export default config;
