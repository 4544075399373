import type { AccountViewsId } from 'types/views/account';
import { ACCOUNT_VIEWS_IDS } from 'types/views/account';

import { getEnvValue, parseEnvJson } from 'configs/app/utils';

const hiddenViews = (() => {
  const parsedValue = parseEnvJson<Array<AccountViewsId>>(getEnvValue('NEXT_PUBLIC_ACCOUNT_HIDDEN_VIEWS')) || [];

  if (!Array.isArray(parsedValue)) {
    return undefined;
  }

  const result = ACCOUNT_VIEWS_IDS.reduce((result, item) => {
    result[item] = parsedValue.includes(item);
    return result;
  }, {} as Record<AccountViewsId, boolean>);

  return result;
})();

const config = Object.freeze({
  hiddenViews,
});

export default config;
